import {
  faCheck,
  faChevronRight,
  faPause,
  faUpload,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  PauseState,
  Sitting,
  SittingDeliveryState,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { useAssessment } from '@sparx/assessments/src/api/hooks';
import { Tooltip } from '@sparx/design/components';
import { useSubject } from 'api/subjects';
import classNames from 'classnames';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { Link } from 'react-router-dom';
import { ElapsedTime } from 'views/teacher/sittingview/SittingView';

import styles from './SittingItem.module.css';

export const SittingItem = ({
  sitting,
  participantCount,
  exportedParticipantCount,
  ended,
  from,
}: {
  sitting: Sitting;
  participantCount: number;
  exportedParticipantCount?: number;
  ended?: boolean;
  from?: string;
}) => {
  const { data: assessment } = useAssessment(
    sitting.assessmentName,
    {
      subjectName: 'subjects/-',
      includeAssessmentGroupAssessments: false,
    },
    { suspense: true },
  );

  const { data: subject } = useSubject(assessment?.subjectKey || '-');

  let meta;
  if (sitting.state?.endTimestamp) {
    meta = (
      <>
        Ended <PrettyTimestamp>{sitting.state.endTimestamp}</PrettyTimestamp>
      </>
    );
  } else if (sitting.state?.startTimestamp) {
    meta = (
      <>
        Started <PrettyTimestamp>{sitting.state.startTimestamp}</PrettyTimestamp>
      </>
    );
  } else {
    meta = (
      <>
        Created <PrettyTimestamp>{sitting.createdTimestamp}</PrettyTimestamp>
      </>
    );
  }

  const exportComplete = exportedParticipantCount === participantCount;

  return (
    <Link
      to={`/teacher/sittings/${sitting.sittingName.split('/')[3]}`}
      state={from ? { from } : undefined}
      className={styles.Item}
    >
      <div className={styles.Title}>
        <h3>{sitting.title}</h3>
        <div className={styles.Meta}>
          <span className={styles.SubjectTag}>{subject?.name}</span>
          {!ended && <>{assessment?.displayName || '<Unknown assessment>'} - </>}Year{' '}
          {sitting?.ukYeargroup} - {meta}
        </div>
      </div>
      <div className={styles.Time}>
        {sitting?.state?.state !== SittingDeliveryState.SCHEDULED && (
          <ElapsedTime sitting={sitting} />
        )}
      </div>
      <>
        {sitting?.state?.state === SittingDeliveryState.SCHEDULED ? (
          <div className={classNames(styles.SittingState, styles.SittingStateScheduled)}>
            Scheduled
          </div>
        ) : sitting?.state?.state === SittingDeliveryState.IN_PROGRESS ? (
          sitting?.state?.pauseState === PauseState.PAUSED ? (
            <div className={classNames(styles.SittingState, styles.SittingStateInPaused)}>
              <FontAwesomeIcon icon={faPause} /> Paused
            </div>
          ) : (
            <div className={classNames(styles.SittingState, styles.SittingStateInProgress)}>
              In progress
            </div>
          )
        ) : sitting?.state?.state === SittingDeliveryState.COMPLETED ? (
          <div className={classNames(styles.SittingState, styles.SittingStateCompleted)}>
            Completed
          </div>
        ) : (
          <div className={classNames(styles.SittingState, styles.SittingStateUnknown)}>Unknown</div>
        )}
      </>
      {(!ended || !sitting.state?.endTimestamp) && (
        <div className={styles.JoinCode}>{sitting.joinCode}</div>
      )}
      {exportedParticipantCount !== undefined && ended ? (
        <Tooltip
          content={
            !exportComplete
              ? 'There are student results not uploaded'
              : 'All student results have been uploaded'
          }
        >
          <div
            className={classNames(
              styles.ParticipantCount,
              exportComplete ? styles.ParticipantCountComplete : styles.ParticipantCountIncomplete,
            )}
          >
            <FontAwesomeIcon icon={exportComplete ? faCheck : faUpload} />
            {exportedParticipantCount} / {participantCount}
          </div>
        </Tooltip>
      ) : (
        <div className={styles.ParticipantCount}>
          <FontAwesomeIcon icon={faUser} />
          {participantCount}
        </div>
      )}
      <div className={styles.Chevron}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </Link>
  );
};
