import { faDownload, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLibAnalytics } from '@sparx/analytics';
import { UploadedAssessmentResult } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { StudentGroupType, YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { getSortingLabel } from '@sparx/assessments/src/components/Common/SortingLabel';
import { SortDirection } from '@sparx/assessments/src/utils/sorting';
import { InfoTooltip } from '@sparx/design/components';
import { Checkbox } from '@sparx/sparx-design/components';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { useMutation } from '@tanstack/react-query';
import { readerReportGenClient } from 'api/clients';
import { useYearGroupMap } from 'api/groups';
import { useSchool } from 'api/school';
import { useSittingUploadedResults } from 'api/sittings';
import { Button } from 'components/button/Button';
import { useClassSelectionStudents } from 'components/header/BackLink';
import { differenceInMonths, format } from 'date-fns';
import { useMemo, useState } from 'react';
import { getStudentGroupTypeForAssessment } from 'utils/assessments';
import {
  calculateSasStanine,
  readingAgeToAbility,
} from 'views/teacher/assessmentsview/reportsview/sasStanine';

import styles from './ReadingTestResultsTable.module.css';
import { SortableColumnName, sortFunctions, TableSorting } from './sorting';

const READING_AGE_MONTHS_ANNOTATION = 'assessment/reading-age-months';

export const ReadingTestResultsTable = ({ assessment }: { assessment: Assessment }) => {
  const groupType = getStudentGroupTypeForAssessment(assessment);
  const { students, groups, selectedGroup } = useClassSelectionStudents(groupType);
  const { data: yearGroups } = useYearGroupMap({ suspense: true });
  if (!selectedGroup) {
    return <>Select a group in header</>;
  }

  return (
    <Table
      assessment={assessment}
      students={students}
      groups={groups}
      yearGroups={yearGroups || new Map()}
    />
  );
};

export interface ReadingResultRow {
  sortableStudentName: string;
  studentName: string;
  yearGroup?: string;
  readerGroup?: string;
  tutorGroup?: string;
  testDate?: Date;
  formattedTestDate?: string;
  monthsAge?: number;
  monthsAgeDisplay?: string;
  readingAge?: number;
  readingAgeDisplay?: string;
  isComplete?: boolean;
  sas?: number;
  stanine?: number;
}

const Table = ({
  assessment,
  students,
  groups,
  yearGroups,
}: {
  assessment: Assessment;
  students: Student[];
  groups: Group[];
  yearGroups: Map<string, YearGroup>;
}) => {
  const { data: results } = useSittingUploadedResults(
    assessment.name,
    students.map(s => `students/${s.studentId}`),
    {
      suspense: true,
      select: data =>
        data.uploadedResults.reduce(
          (acc, result) => {
            const studentId = result.studentName.split('/')[1];
            if (!acc[studentId]) acc[studentId] = [result];
            else acc[studentId].push(result);
            return acc;
          },
          {} as Record<string, UploadedAssessmentResult[]>,
        ),
    },
  );

  const groupLookup = useMemo(
    () =>
      groups.reduce(
        (acc, group) => {
          acc[group.name.split('/')[3]] = group;
          return acc;
        },
        {} as Record<string, Group>,
      ),
    [groups],
  );

  const [sortedBy, updateSortedBy] = useState<TableSorting>({
    dir: SortDirection.Ascending,
    sortBy: SortableColumnName.StudentName,
    sortFn: sortFunctions[SortableColumnName.StudentName],
  });

  const sortClickHandler = (name: SortableColumnName) => {
    const newDirection = sortedBy.sortBy === name ? -sortedBy.dir : SortDirection.Ascending;
    updateSortedBy({
      dir: newDirection,
      sortBy: name,
      sortFn: sortFunctions[name],
    });
  };

  const rows = useMemo(
    () =>
      students.flatMap((student): ReadingResultRow[] => {
        const studentGroups = student.studentGroupIds.map(id => groupLookup[id]).filter(g => !!g);
        const readerGroup = studentGroups.find(g => g.type === StudentGroupType.CLASS_ENGLISH);
        const tutorGroup = studentGroups.find(g => g.type === StudentGroupType.TUTORGROUP);
        const dob = optionalTimestamp(student.dateOfBirth);

        const yearGroup = yearGroups.get(readerGroup?.yearGroupId || tutorGroup?.yearGroupId || '');

        const staticData: ReadingResultRow = {
          studentName: `${student.givenName} ${student.familyName}`,
          sortableStudentName: `${student.familyName}, ${student.givenName}`,
          yearGroup: yearGroup?.name,
          readerGroup: readerGroup?.displayName,
          tutorGroup: tutorGroup?.displayName,
        };

        const studentResults = results?.[student.studentId];

        if (!studentResults || studentResults?.length === 0) {
          return [staticData];
        }

        return studentResults.map(result => {
          let readingAgeMonths;
          try {
            const readingAge = result.package?.annotations?.[READING_AGE_MONTHS_ANNOTATION];
            if (readingAge) {
              readingAgeMonths = parseInt(readingAge || '0');
            }
          } catch (e) {
            console.error('Failed to parse reading age', e);
          }

          const testDate = optionalTimestamp(result?.createdTimestamp);
          const monthsAge = dob && testDate ? differenceInMonths(testDate, dob) : undefined;
          const isComplete = result.package?.state?.completionTimestamp !== undefined;

          const formattedTestDate = testDate ? format(testDate, 'dd/MM/yyyy') : '-';

          let sasStanine = {};
          const ageAtTestFloat = monthsAge ? monthsAge / 12 : undefined;
          if (readingAgeMonths && ageAtTestFloat) {
            const readingAbility = readingAgeToAbility(readingAgeMonths / 12);
            sasStanine = calculateSasStanine(readingAbility, ageAtTestFloat);
          }

          return {
            ...staticData,
            testDate,
            formattedTestDate,
            monthsAge,
            monthsAgeDisplay: monthsToDisplay(monthsAge),
            readingAge: readingAgeMonths,
            readingAgeDisplay: monthsToDisplay(readingAgeMonths),
            isComplete,
            ...sasStanine,
          };
        });
      }),
    [yearGroups, students, results, groupLookup],
  );

  const sortedRows = useMemo(() => rows.sort(sortedBy.sortFn()(sortedBy.dir)), [rows, sortedBy]);

  if (!results) {
    throw new Error('Results not found');
  }

  const sortHeader = (
    name: string,
    column: SortableColumnName,
    opts?: {
      nowrap?: boolean;
    },
  ) => (
    <th style={opts?.nowrap ? { whiteSpace: 'nowrap' } : undefined}>
      {getSortingLabel(sortedBy, sortClickHandler, column, name)}
    </th>
  );

  const table = (
    <table className={styles.Table}>
      <thead>
        <tr className={styles.TableHeadRow}>
          {sortHeader('Name', SortableColumnName.StudentName, { nowrap: true })}
          {sortHeader('Year', SortableColumnName.Year, { nowrap: true })}
          {sortHeader('Class', SortableColumnName.Class, { nowrap: true })}
          {sortHeader('Reg. group', SortableColumnName.RegGroup)}
          {sortHeader('Date of assessment', SortableColumnName.Date)}
          {sortHeader('Age at test (yy:mm)', SortableColumnName.AgeAtTest)}
          {sortHeader('Reading age (yy:mm)', SortableColumnName.ReadingAge)}
          {sortHeader('SAS', SortableColumnName.SAS, { nowrap: true })}
          {sortHeader('Stanine', SortableColumnName.Stanine, { nowrap: true })}
          <th>Completed</th>
        </tr>
      </thead>
      <tbody>
        {sortedRows.map((row, i) => (
          <tr key={i} className={styles.ThinTableRow}>
            <td>{row.studentName}</td>
            <td>{row.yearGroup || '-'}</td>
            <td>{row.readerGroup || '-'}</td>
            <td>{row.tutorGroup || '-'}</td>
            <td>{row.formattedTestDate || '-'}</td>
            <td>{row.monthsAgeDisplay || '-'}</td>
            <td>{row.readingAgeDisplay || '-'}</td>
            <td>{row.sas || '-'}</td>
            <td>{row.stanine || '-'}</td>
            <td>{!row.formattedTestDate || row.isComplete ? '-' : 'Incomplete'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <>
      <div className={styles.Top}>
        <div className={styles.TopInfo}>
          <ReaderSRTWarning />
        </div>
        <DownloadReportModal />
      </div>
      {table}
    </>
  );
};

const DownloadReportModal = () => {
  const sendEvent = useLibAnalytics();

  const { data: school } = useSchool();
  const hasReader = school?.products.includes(Product.SPARX_READER);

  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);

  const [includeDemographicData, setIncludeDemographics] = useState(false);
  const { mutate, isLoading } = useMutation({
    mutationFn: async () =>
      readerReportGenClient.getAssessmentReport({
        schoolName: school?.name || '',
        includeDemographicData,
        includeSasStanines: true,
      }).response,
    onMutate: () => {
      sendEvent({
        category: 'reports',
        action: 'download',
        labels: { type: 'readertest-report', includeDemographicData: includeDemographicData },
      });
    },
    onSuccess: data => {
      download(data.downloadUrl);
      onClose(); // close the modal after download
    },
  });

  return (
    <>
      <Modal isOpen={open} onClose={onClose}>
        <Modal.Content width="lg">
          <Modal.CloseButton />
          <Modal.Title>Download Reading Test Report</Modal.Title>
          <Modal.Body className={styles.Download}>
            <div className={styles.DownloadIcon}>
              <FontAwesomeIcon icon={faFileExcel} />
            </div>
            <div className={styles.DownloadText}>
              Download a whole school reading test report showing every teach each student has
              completed and their reading ages.{' '}
              {hasReader && (
                <>
                  This <strong>will</strong> include SRT results from Sparx Reader
                </>
              )}
              <div className={styles.DemographicCheckbox}>
                <Checkbox
                  label="Include student demographic data"
                  id="demographics"
                  onCheckedChange={checked => setIncludeDemographics(!!checked)}
                />
                <InfoTooltip content={demographicTooltip} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button colour="grey" onClick={onClose} isDisabled={isLoading}>
              Cancel
            </Button>
            <Button
              colour="blue"
              onClick={() => mutate()}
              isLoading={isLoading}
              rightIcon={<FontAwesomeIcon icon={faDownload} />}
            >
              Download report (excel)
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Button onClick={() => setOpen(true)} rightIcon={<FontAwesomeIcon icon={faDownload} />}>
        Download report
      </Button>
    </>
  );
};

const download = (url: string) => {
  if (!url) {
    return;
  }
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = '';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

const demographicTooltip = (
  <>
    Provided we have permission to access the data in your school&apos;s MIS, your report will
    include: pupil premium, free school meals, English as an additional language, and gender data.
  </>
);

const ReaderSRTWarning = () => {
  const { data: school } = useSchool();
  const hasReader = school?.products.includes(Product.SPARX_READER);

  if (hasReader) {
    return <div>This report does not include SRT results from Sparx Reader.</div>;
  }
  return null;
};

const optionalTimestamp = (ts: Timestamp | undefined): Date | undefined =>
  ts ? Timestamp.toDate(ts) : undefined;

const monthsToDisplay = (months: number | undefined) => {
  if (!months) return '-';

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;
  return `${years}:${remainingMonths < 10 ? '0' : ''}${remainingMonths}`;
};
