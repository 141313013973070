import {
  faCheckCircle,
  faExternalLink,
  faFileLines,
  faGlobe,
  faListCheck,
  faPencil,
  faPlus,
  faTableCells,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Tooltip } from '@sparx/design/components';
import { useSchool } from 'api/school';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { InProductButton } from 'components/button/InProductButton';
import queryString from 'query-string';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { followUpName } from 'utils/assessments';
import styles from 'views/teacher/assessmentsview/AssessmentHomeView.module.css';
import assessmentDataIcon from 'views/teacher/assessmentsview/images/icon_assessment_data.png';
import iconDigital from 'views/teacher/assessmentsview/images/icon_digital_assessment.png';
import iconMatchStudents from 'views/teacher/assessmentsview/images/icon_match_students.png';
import iconResources from 'views/teacher/assessmentsview/images/icon_resources.png';
import iconStudentSubmissions from 'views/teacher/assessmentsview/images/icon_student_submissions.png';
import iconAssessment from 'views/teacher/assessmentsview/images/icon-assessment.svg';
import iconFollowUp from 'views/teacher/assessmentsview/images/icon-follow-up.svg';
import iconRevision from 'views/teacher/assessmentsview/images/icon-revision.svg';

const Card = ({
  title,
  children,
  image,
  paper,
  online,
}: PropsWithChildren<{
  title: string;
  image?: string;
  paper?: boolean;
  online?: boolean;
}>) => (
  <div className={styles.Card}>
    <div className={styles.CardIcon}>
      {paper && (
        <Tooltip content="Applies to paper assessments">
          <FontAwesomeIcon icon={faFileLines} fixedWidth={true} />
        </Tooltip>
      )}
      {online && (
        <Tooltip content="Applies to online assessments">
          <FontAwesomeIcon icon={faGlobe} fixedWidth={true} />
        </Tooltip>
      )}
    </div>
    {image ? <img src={image} alt="" /> : <div className={styles.PlaceholderImage} />}
    <div className={styles.CardDetails}>
      <h3>{title}</h3>
      {children}
    </div>
  </div>
);

export const RevisionCard = ({ assessment }: { assessment: Assessment }) => {
  const { data: school } = useSchool();
  const revisionUrl =
    window.settings?.sparxMathsUrl +
    `/teacher/${assessment.name}/revision/progress?s=${school?.urlPrefix}`;

  return (
    <Card title="Revision" image={iconRevision}>
      <p>
        Prepare your students for this assessment by enabling a package of work with a range of
        questions from the topics covered in the assessment.
      </p>
      <div className={styles.CardButtons}>
        <InProductButton feature="View revision" as={Link} to={revisionUrl} target="_blank" />
      </div>
    </Card>
  );
};

export const SetAssessmentCard = ({ assessment }: { assessment: Assessment }) => (
  <Card title="Set Assessment" image={iconDigital} online={true}>
    <p>Set up a online assessment and assign it to your classes.</p>
    <div className={styles.CardButtons}>
      <Button
        as={Link}
        to={`/teacher/sittings?${queryString.stringify({
          assessment: assessment.name,
          subject: assessment.subjectKey,
        })}`}
        leftIcon={<FontAwesomeIcon icon={faListCheck} />}
      >
        View active sittings
      </Button>
      <Button
        as={Link}
        to={`/teacher/sittings/create?${queryString.stringify({
          assessment: assessment.name,
        })}`}
        variant="ghost"
        leftIcon={<FontAwesomeIcon icon={faPlus} />}
      >
        Create sitting
      </Button>
    </div>
  </Card>
);

export const MatchStudentsCard = ({
  setTab,
  totalStudents,
  exportedStudents,
}: {
  setTab: (tab: string) => void;
  totalStudents: number;
  exportedStudents: number;
}) => {
  const shouldWarn = exportedStudents < totalStudents;

  return (
    <Card title="Match Students" image={iconMatchStudents} online={true}>
      <p>
        Match students from the online assessment to those in the MIS and export their data for
        reporting and insights.
      </p>
      <div className={styles.CardButtons}>
        <Button onClick={() => setTab('sittings')}>View sittings</Button>
        <div
          className={classNames(
            styles.CardStatus,
            shouldWarn ? styles.CardStatusWarn : styles.CardStatusOk,
          )}
        >
          <FontAwesomeIcon icon={shouldWarn ? faWarning : faCheckCircle} />
          {exportedStudents}/{totalStudents} exported student
          {totalStudents === 1 ? '' : 's'}
        </div>
      </div>
    </Card>
  );
};

export const ResourcesCard = ({ link }: { link: string }) => (
  <Card title="Resources" image={iconResources} paper={true}>
    <p>Download and distribute the assessment for your students to complete.</p>
    <div className={styles.CardButtons}>
      <Button
        as={Link}
        to={link}
        rightIcon={<FontAwesomeIcon icon={faExternalLink} />}
        target="_blank"
      >
        Download Resources
      </Button>
    </div>
  </Card>
);

export const MarksheetCard = ({
  assessment,
  setTab,
}: {
  assessment: Assessment;
  setTab: (tab: string) => void;
}) => (
  <Card title="Marksheet" image={iconAssessment} paper={true}>
    <p>
      Enter in your student QLA data from their paper assessment to access reporting and insights.
    </p>
    <div className={styles.CardButtons}>
      <Button
        onClick={() => setTab('marksheet')}
        leftIcon={<FontAwesomeIcon icon={faTableCells} />}
      >
        View Marksheet
      </Button>
      <Button
        variant="ghost"
        as={Link}
        to={`/teacher/${assessment.name}/dataentry`}
        leftIcon={<FontAwesomeIcon icon={faPencil} />}
      >
        Enter data
      </Button>
    </div>
  </Card>
);

export const ReportsCard = ({ setTab }: { setTab: (tab: string) => void }) => (
  <Card title="Reports" image={assessmentDataIcon}>
    <p>View student, class and cohort reports.</p>
    <div className={styles.CardButtons}>
      <div className={styles.CardButtons}>
        <Button onClick={() => setTab('reports')}>View reports</Button>
      </div>
    </div>
  </Card>
);

export const FollowUpCard = ({
  assessment,
  setTab,
}: {
  assessment: Assessment;
  setTab: (tab: string) => void;
}) => (
  <Card title={followUpName(assessment)} image={iconFollowUp}>
    <p>
      Enable a &apos;Fluency Fix Up&apos; task for your students to go back and reattempt any
      incorrect answers and gain class level insights of Problem Solving questions.
    </p>
    <div className={styles.CardButtons}>
      <Button onClick={() => setTab('followup')}>View {followUpName(assessment)}</Button>
    </div>
  </Card>
);

export const StudentSubmissionsCard = ({ assessment }: { assessment: Assessment }) => (
  <Card title="Student submissions" image={iconStudentSubmissions} online={true}>
    <p>View online student submissions.</p>
    <div className={styles.CardButtons}>
      <Button as={Link} to={`/teacher/${assessment.name}/submissions`}>
        View submissions
      </Button>
    </div>
  </Card>
);
