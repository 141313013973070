import {
  faChevronDown,
  faChevronUp,
  faHourglass,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLibAnalytics } from '@sparx/analytics';
import {
  ExportResultsRequest_Choice,
  ExportResultsRequest_Result,
  ListSittingsResponse_SittingData,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Package } from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { Tooltip } from '@sparx/design/components';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getSchoolID } from 'api/auth';
import { sittingsClient } from 'api/clients';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { MatchMethodCell, SittingNameCell } from 'components/matchtable/Cells';
import styles from 'components/matchtable/MatchTable.module.css';
import { ResolveParticipantMatch, SittingLookup } from 'components/matchtable/utils';
import { useMemo, useState } from 'react';

interface ResolvingTableProps {
  sittings?: SittingLookup;
  assessment: Assessment;
  participants: ResolveParticipantMatch[];
  exportedParticipants: ResolveParticipantMatch[];
  onSave?: () => Promise<unknown>;
}

export const ExportResultsTable = (props: ResolvingTableProps) => {
  const { data } = useQuery({
    queryKey: ['assessment', props.assessment.name, 'results'],
    suspense: true,
    queryFn: async () =>
      sittingsClient.listAssessmentSittingPackages({
        schoolName: `schools/${await getSchoolID()}`,
        assessmentName: props.assessment.name,
      }).response,
  });

  const packageLookup = useMemo(() => {
    const latestStudentPackage = new Map<string, Package>();
    for (const pkg of data?.packages || []) {
      if (!pkg.package) continue; // skip

      const key = `${pkg.studentId}:${pkg.package.parentName}`;
      const existing = latestStudentPackage.get(key);
      if (
        !existing ||
        (existing.startTimestamp?.seconds || 0) < (pkg.package.startTimestamp?.seconds || 0)
      ) {
        latestStudentPackage.set(key, pkg.package);
      }
    }
    return latestStudentPackage;
  }, [data]);

  return <ResolvingTableImpl {...props} packageLookup={packageLookup} />;
};

interface ExportState {
  canExport: boolean;
  partialExport?: boolean;
  reason: string;
  why?: string;
}

const ResolvingTableImpl = ({
  sittings,
  assessment,
  participants,
  exportedParticipants,
  onSave,
  packageLookup,
}: ResolvingTableProps & { packageLookup: Map<string, Package> }) => {
  const sendEvent = useLibAnalytics();
  const [showIgnored, setShowIgnored] = useState(false);

  const alreadyExportedLookup = useMemo(() => {
    const lookup = new Set<string>();
    for (const p of exportedParticipants) {
      if (p.resolvedStudentId) {
        lookup.add(p.resolvedStudentId);
      }
    }
    return lookup;
  }, [exportedParticipants]);

  const linkCount = useMemo(() => {
    const counts: Record<string, number> = {};
    for (const p of participants) {
      const userIdParts = p.participant.participantSubject.split('/');
      const key = `${userIdParts[userIdParts.length - 1]}:${p.participant.sittingName}`;
      const pkg = packageLookup.get(key);

      if (pkg && p.resolvedStudentId) {
        counts[p.resolvedStudentId] = (counts[p.resolvedStudentId] || 0) + 1;
      }
    }
    return counts;
  }, [packageLookup, participants]);

  const rows: Row[] = useMemo(
    () =>
      participants
        .sort(
          (a, b) =>
            (a.participant.ignoreResult ? 1 : 0) - (b.participant.ignoreResult ? 1 : 0) ||
            a.matchedDetails.givenName.localeCompare(b.matchedDetails.givenName) ||
            a.matchedDetails.familyName.localeCompare(b.matchedDetails.familyName),
        )
        .map(p => {
          const hasDuplicate = Boolean(p.resolvedStudentId && linkCount[p.resolvedStudentId] > 1);
          const hasPrevious = Boolean(
            p.resolvedStudentId && alreadyExportedLookup.has(p.resolvedStudentId),
          );

          const sitting = sittings?.[p.participant.sittingName];

          const userIdParts = p.participant.participantSubject.split('/');
          const key = `${userIdParts[userIdParts.length - 1]}:${p.participant.sittingName}`;
          const pkg = packageLookup.get(key);
          const state = getExportState(pkg, assessment, sitting);

          return { ...p, hasDuplicate, hasPrevious, state, sitting, pkg, key };
        }),
    [participants, linkCount, alreadyExportedLookup, packageLookup, assessment, sittings],
  );

  const [choices, setChoices] = useState<Record<string, Choice | undefined>>(() =>
    getInitialChoices(rows),
  );

  const exportRow = (key: string) => {
    setChoices(c => {
      sendEvent({
        category: 'export results table',
        action: 'toggle export',
        labels: {
          participant: key,
          current: c[key]?.choice || 'none',
          assessment: assessment.name,
        },
      });

      if (c[key]?.choice === 'export') {
        return { ...c, [key]: undefined };
      }

      const changes: Record<string, Choice> = { [key]: { choice: 'export' } };

      // Find other students in the list with the same studentId
      const participant = rows.find(p => p.key === key);
      const otherStudents = rows.filter(
        p =>
          p.resolvedStudentId === participant?.resolvedStudentId &&
          p !== participant &&
          p.pkg &&
          (p.state.canExport || p.state.partialExport),
      );
      for (const other of otherStudents) {
        changes[other.key] = { choice: 'ignore' };
      }

      return { ...c, ...changes };
    });
  };

  const ignoreRow = (key: string) => {
    setChoices(c => {
      const participant = rows.find(p => p.key === key);

      sendEvent({
        category: 'export results table',
        action: 'toggle ignore',
        labels: {
          participant: key,
          current: c[key]?.choice || 'none',
          alreadyIgnored: participant?.participant?.ignoreResult ? 'yes' : 'no',
          assessment: assessment.name,
        },
      });

      if (c[key]?.choice === 'ignore' || participant?.participant.ignoreResult) {
        return { ...c, [key]: undefined };
      }
      return { ...c, [key]: { choice: 'ignore' } };
    });
  };

  const { mutateAsync: save, isLoading } = useMutation({
    mutationFn: async () =>
      sittingsClient.exportResults({
        schoolName: `schools/${await getSchoolID()}`,
        results: rows
          .map(p => {
            const choice = choices[p.key];
            if (!choice?.choice) return null;

            return {
              choice:
                choice.choice === 'export'
                  ? ExportResultsRequest_Choice.EXPORT
                  : ExportResultsRequest_Choice.IGNORE,
              packageName: p.pkg?.name || '',
              participantName: p.participant.participantSubject,
              sittingName: p.sitting?.sitting?.sittingName || '',
            } satisfies ExportResultsRequest_Result;
          })
          .filter(a => a !== null),
      }).response,
    onSuccess: async () => {
      await onSave?.();
      setChoices({});
    },
  });

  const ignoredRowCount = rows.filter(p => p.participant.ignoreResult).length;
  const outstandingRowCount = rows.filter(p => !p.participant.ignoreResult).length;

  const table = (
    <table className={styles.Table}>
      <thead>
        <tr>
          <th>Group name</th>
          <th>Student</th>
          <th>
            Join method
            <Tooltip content="Specifies how the student accessed the assessment, either through their Sparx login or another method.">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </th>
          <th>
            Progress
            <Tooltip content="Displays student progress to help decide whether to export or ignore results, particularly when dealing with duplicate entries or partial completions.">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </th>
          <th>
            Status
            <Tooltip content="Shows the current export status. Any status other than 'Pending' requires action to export the student's data.">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </th>
          <th>
            Action
            <Tooltip
              content={
                <>
                  <p>Provides options for managing student data export:</p>
                  <ul style={{ marginTop: 10 }}>
                    <li>Keep: Export the data.</li>
                    <li>Ignore: Do not export the data.</li>
                    <li>Can&apos;t export: Insufficient data to generate results.</li>
                  </ul>
                </>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        {outstandingRowCount === 0 && (
          <tr>
            <td colSpan={6} className={styles.NoRows}>
              There are no outstanding results to export
            </td>
          </tr>
        )}
        {rows
          .filter(p => (showIgnored ? true : !p.participant.ignoreResult))
          .map((p, i) => {
            const choice = choices[p.key];
            const disabled = !p.pkg;

            let className = '';
            let status = '-';
            if (disabled) {
              className = styles.RowDisabled;
              status = 'No progress';
            } else if (choice?.choice === 'export') {
              className = styles.RowPending;
              status = 'Pending...';
            } else if (choice?.choice === 'ignore') {
              className = classNames(styles.RowPending, styles.RowIgnored);
              status = 'Ignored';
            } else if (p.participant.ignoreResult) {
              className = styles.RowIgnored;
              status = 'Ignored';
            } else if (p.hasDuplicate) {
              status = 'Duplicate';
              className = styles.RowDanger;
            } else if (p.hasPrevious) {
              status = 'Already exported';
              className = styles.RowDanger;
            }

            const prev = rows[i - 1]?.resolvedStudentId === p.resolvedStudentId;
            const next = rows[i + 1]?.resolvedStudentId === p.resolvedStudentId;

            const canExport = p.state.canExport || p.state.partialExport;
            const isIgnored = !choice ? p.participant.ignoreResult : choice?.choice === 'ignore';

            return (
              <tr key={p.key} className={className}>
                <SittingNameCell sitting={p.sitting} />
                <td>
                  {(prev || next) && (
                    <div
                      className={classNames(styles.ConnectDot, prev && styles.ConnectDotAfter)}
                    />
                  )}
                  {p.matchedDetails?.givenName} {p.matchedDetails?.familyName}
                </td>
                <MatchMethodCell method={p.matchMethod} />
                <td>
                  {p.sitting && (
                    <PackageCompletionStatus
                      pkg={p.pkg}
                      assessment={assessment}
                      sitting={p.sitting}
                    />
                  )}
                </td>
                <td className={styles.StatusColumn}>
                  {choice && (
                    <Tooltip content="This change has not been saved yet">
                      <FontAwesomeIcon icon={faHourglass} />
                    </Tooltip>
                  )}
                  {status}
                </td>
                <td className={styles.Controls}>
                  {canExport ? (
                    <Stack spacing={2}>
                      <Button
                        size="sm"
                        colour={choice?.choice === 'export' ? 'green' : 'grey'}
                        variant={choice?.choice === 'export' ? 'solid' : 'outline'}
                        onClick={() => exportRow(p.key)}
                      >
                        Keep
                      </Button>
                      <Button
                        size="sm"
                        colour={isIgnored ? 'red' : 'grey'}
                        onClick={() => ignoreRow(p.key)}
                        variant={isIgnored ? 'solid' : 'outline'}
                      >
                        Ignore
                      </Button>
                    </Stack>
                  ) : (
                    <i className={styles.SecondaryText}>
                      Can&apos;t export{p.state.why && ':'} {p.state.why}
                    </i>
                  )}
                </td>
              </tr>
            );
          })}
        {ignoredRowCount > 0 && (
          <tr>
            <td
              colSpan={7}
              className={styles.ShowMoreButton}
              onClick={() => setShowIgnored(!showIgnored)}
            >
              <FontAwesomeIcon icon={showIgnored ? faChevronUp : faChevronDown} />
              {showIgnored ? 'Hide' : 'Show'} {ignoredRowCount} ignored student
              {ignoredRowCount === 1 ? '' : 's'}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );

  const changes = Object.values(choices).filter(Boolean).length;
  const saveButton = (
    <Button
      isDisabled={changes === 0}
      onClick={() => save()}
      isLoading={isLoading}
      className={styles.SaveButton}
    >
      Export {changes} change{changes === 1 ? '' : 's'}
    </Button>
  );

  return (
    <>
      <Stack spacing={4} style={{ marginBottom: 'var(--spx-unit-5)' }}>
        <p>
          Use this page to resolve any issues with the results that are ready for export. If a
          student has duplicate entries, you can choose which result to keep or ignore. You can also
          decide to keep or ignore results for students with partial progress. Any undecided results
          (i.e., those for which you haven’t clicked &apos;keep&apos; or &apos;ignore&apos;) will
          remain on this screen. When you&apos;re ready, click <i>&apos;Export changes&apos;</i> to
          finalize the export, after which you can access the results in the reporting section.
        </p>
        {saveButton}
      </Stack>
      {table}
    </>
  );
};

const PackageCompletionStatus = ({
  pkg,
  assessment,
  sitting,
}: {
  pkg?: Package;
  assessment: Assessment;
  sitting: ListSittingsResponse_SittingData;
}) => {
  const state = useMemo(() => getExportState(pkg, assessment, sitting), [pkg, assessment, sitting]);

  return (
    <span
      className={
        state.canExport
          ? styles.ExportSuccess
          : state.partialExport
            ? styles.ExportWarning
            : styles.ExportIncomplete
      }
    >
      {state.reason}
    </span>
  );
};

const getExportState = (
  pkg: Package | undefined,
  assessment: Assessment,
  sitting: ListSittingsResponse_SittingData | undefined,
): ExportState => {
  if (!pkg) {
    return { canExport: false, reason: 'Incomplete (0%)', why: 'not started' };
  }
  if (!sitting) {
    return { canExport: false, reason: 'No sitting' };
  }

  if (assessment.subjectKey === 'english') {
    // English requires 100 completion
    if (pkg.state?.completion && pkg.state.completion.size === pkg.state.completion.progress['C']) {
      return { canExport: true, reason: 'Completed' };
    } else {
      const hasSomeProgress = pkg.state?.completion
        ? (pkg.state.completion.progress['C'] || 0) / (pkg.state.completion.size || 1)
        : 0;
      if (hasSomeProgress > 0.5) {
        return {
          canExport: false,
          reason: `Incomplete (${Math.round(hasSomeProgress * 100)}%)`,
          partialExport: true,
        };
      }
      return {
        canExport: false,
        why: '<50% complete',
        reason: `Incomplete (${Math.round(hasSomeProgress * 100)}%)`,
      };
    }
  }

  // Science and others requires sitting to have ended
  if (sitting.sitting?.state?.endTimestamp) {
    return { canExport: true, reason: 'Sitting ended' };
  }
  return { canExport: false, reason: 'Sitting in progress', why: 'sitting in progress' };
};

interface Row extends ResolveParticipantMatch {
  hasDuplicate: boolean;
  hasPrevious: boolean;
  state: ExportState;
  pkg: Package | undefined;
  sitting: ListSittingsResponse_SittingData | undefined;
  key: string;
}

interface Choice {
  choice: 'export' | 'ignore';
}

const getInitialChoices = (rows: Row[]) => {
  const choices: Record<string, Choice> = {};

  for (const p of rows) {
    if (
      p.state.canExport &&
      !p.hasDuplicate &&
      !p.hasPrevious &&
      !p.participant.ignoreResult &&
      p.pkg
    ) {
      choices[p.key] = { choice: 'export' };
    }
  }
  return choices;
};
