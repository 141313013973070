import * as Sentry from '@sentry/react';
import { GetCurrentSessionResponse } from '@sparx/api/apis/sparx/assessment/app/v1/authentication';
import { isSystemAccessError } from '@sparx/api/utils/rpc-error';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { authenticationClientWithAuth, startAccessTokenProvider } from 'api/clients';
import { queryClient } from 'app/queryClient';
import queryString from 'query-string';
import { validate } from 'uuid';

const LOCAL_STORAGE_KEY = 'spx/school';

const getSchoolFromLocalStorage = () => {
  const uuid = localStorage.getItem(LOCAL_STORAGE_KEY) || '';
  if (!uuid) {
    return undefined;
  }
  if (!validate(uuid)) {
    // Clear the item
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    return undefined;
  }
  return uuid;
};

export const getSchoolFromUrl = () => {
  const url = new URL(window.location.href);
  const uuid = url.searchParams.get('school');
  if (!uuid) {
    return undefined;
  }
  if (!validate(uuid)) {
    return undefined;
  }
  return uuid;
};

export const storeSchoolId = (schoolId: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, schoolId);
};

export const loadSchoolId = () => {
  const localStorageSchool = getSchoolFromLocalStorage();
  const urlSchool = getSchoolFromUrl();
  if (urlSchool && urlSchool !== localStorageSchool) {
    storeSchoolId(urlSchool);
    return urlSchool;
  }
  return localStorageSchool;
};

export const getSelectSchoolUrl = (route?: string, forget?: boolean) => {
  const query = queryString.stringify({
    app: 'sparx_assessments',
    route,
    ...(forget ? { forget: 1 } : {}),
  });
  return window.settings?.selectSchoolUrl + '?' + query;
};

export const redirectToSelectSchool = async (route?: string, forget?: boolean) => {
  window.location.replace(getSelectSchoolUrl(route, forget));
  return new Promise(() => {
    /*pass*/
  }); // never return
};

export const redirectToRoot = async () => {
  window.location.replace('/');
  return new Promise(() => {
    /*pass*/
  }); // never return
};

export const redirectToLogin = async (
  schoolID?: string,
  opts?: { forget?: boolean; path?: string },
) => {
  if (!schoolID) {
    schoolID = loadSchoolId();
  }
  const { selectschool, is, fg } = queryString.parse(window.location.search);
  const shouldSelectSchool = Boolean(selectschool?.toString()) || opts?.forget;
  const route = opts?.path ? `${window.location.origin}${opts.path}` : window.location.toString();
  const isQuery = is?.toString() ? { is: 'true' } : {};
  const fgQuery = fg?.toString() ? { fg: '1' } : {};

  if (!schoolID || shouldSelectSchool) {
    // Redirect to select school
    return redirectToSelectSchool(route, shouldSelectSchool);
  } else {
    // Redirect straight to auth endpoint
    const query = queryString.stringify({ school: schoolID, route, ...isQuery, ...fgQuery });
    window.location.replace(window.settings?.authUrl + '/oauth2/login?' + query);
  }
  return new Promise(() => {
    /*pass*/
  }); // never return
};

interface iToken {
  sub?: string;
  sch?: string;
  spx?: {
    sch?: string;
  };
}

type Token = {
  subject?: string;
  school?: string;
};

const parseToken = (token: string): Token => {
  token = token.replace('bearer ', '');
  const parts = token.split('.');
  const payload = JSON.parse(atob(parts[1]));
  const itok = payload as iToken;
  return {
    subject: itok.sub,
    school: itok.spx?.sch ?? itok.sch,
  };
};

export const getToken = () =>
  fetch(window.settings?.authUrl + '/token', {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  });

export const logout = async (noRedirect?: boolean) =>
  fetch(window.settings?.authUrl + '/oauth2/logout', {
    credentials: 'include',
  }).then(() => {
    if (!noRedirect) {
      return redirectToRoot();
    }
  });

const sessionQuery: UseQueryOptions<GetCurrentSessionResponse, Error> = {
  queryKey: ['session'],
  queryFn: async () => {
    return await authenticationClientWithAuth.getCurrentSession({}).response;
  },
  cacheTime: Infinity,
  staleTime: Infinity,
  refetchInterval: 1000 * 60 * 5, // 5 minutes
};

export const useSession = (opts?: UseQueryOptions<GetCurrentSessionResponse, Error>) =>
  useQuery({ ...sessionQuery, ...opts });

export const invalidateSession = () => queryClient.invalidateQueries(sessionQuery.queryKey);

export const getSession = async () => queryClient.fetchQuery(sessionQuery);

export const getSchoolID = async () => getSession().then(data => data.schoolId);

const sessionPromise = async () => {
  const token = await getToken();
  if (token.ok && token.status === 200) {
    const tokenData = await token.text();
    await startAccessTokenProvider(tokenData);

    const urlSchoolID = getSchoolFromUrl();
    const tokenValues = parseToken(tokenData);
    if (urlSchoolID && tokenValues.school !== urlSchoolID) {
      await redirectToLogin(urlSchoolID);
    } else if (tokenValues.school) {
      storeSchoolId(tokenValues.school); // Ensure session school is stored
    }

    const resp = await queryClient.fetchQuery(sessionQuery);

    // Set the sentry values
    Sentry.setUser({ id: resp.subject });
    Sentry.setTags({
      userType: resp.userType,
      schoolId: resp.schoolId,
      sparxUserId: resp.userId,
    });

    return resp;
  } else {
    return false;
  }
};

export const useSessionWithAnonymous = (opts?: { suspense?: boolean }) =>
  useQuery({
    queryKey: ['session', 'token'],
    queryFn: sessionPromise,
    cacheTime: Infinity, // Remember forever
    staleTime: Infinity, // Remember forever
    retry: (count: number, error: Error) => {
      // Don't bother retrying on system access errors.
      if (isSystemAccessError(error)) {
        return false;
      }
      return count < 3;
    },
    ...opts,
  });
